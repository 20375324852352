export function getRoomRequests(state) {
  const { request, basket } = state
  return basket.map(basketItem => ({
    from: request.from,
    to: request.to,
    adults: basketItem.adults,
    children: basketItem.childrenAges,
    unitTypeId: basketItem.unitTypeId,
    ratePlanId: basketItem.ratePlanId,
    promoCode: basketItem.activePromoCode?.code || null,
    services: basketItem.services
      .filter(service => service.quantity > 0)
      .map(service => ({
        serviceId: service.id,
        quantity: service.quantity
      })),
    amenities: basketItem.amenities
  }))
}
